import { createContext, ReactNode, useState, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import { loyaltyProgramDataKey } from '@/config/axios';
import { useRestaurantContext } from '@/contexts/restaurant';
import { ILoyaltyProgram, LoyaltyProgramType } from '@/interfaces/loyalty/types';
import LoyaltyService from '@/services/loyalty';
import { useUserContext } from '@/contexts/user';
import { restrictRoles } from '@/constants/roleRestrictions';

interface ILoyaltyContext {
    loading: boolean;
    programs: ILoyaltyProgram[];
    setPrograms: Dispatch<SetStateAction<ILoyaltyProgram[]>> | null;
    selectedProgram: ILoyaltyProgram | null;
    setSelectedProgram: ((program: ILoyaltyProgram) => void) | null;
}

function getLocalProgram(): ILoyaltyProgram | null {
    try {
        const item = localStorage.getItem(loyaltyProgramDataKey);
        if (item) {
            return JSON.parse(item);
        }
    } catch {
        return null;
    }
    return null;
}

function setLocalProgram(item: ILoyaltyProgram | null) {
    localStorage.setItem(loyaltyProgramDataKey, JSON.stringify(item));
}

const LoyaltyContext = createContext<ILoyaltyContext>({
    loading: true,
    programs: [],
    setPrograms: null,
    selectedProgram: null,
    setSelectedProgram: null,
});

type Props = {
    children?: ReactNode;
};

export function LoyaltyContextProvider({ children }: Props) {
    const { user } = useUserContext();
    const { restaurant } = useRestaurantContext();
    const loyaltyService = LoyaltyService.getInstance();
    const [loading, setLoading] = useState(true);
    const [programs, setPrograms] = useState<ILoyaltyProgram[]>([]);
    const [selectedProgram, setSelectedProgram] = useState<ILoyaltyProgram | null>(getLocalProgram());

    function getLoyaltyPrograms() {
        if (!restaurant?.restaurant_unique || !restrictRoles.LOYALTY.includes(user?.userData?.role)) return;

        setLoading(true);
        loyaltyService
            .getRestaurantEnrolledPrograms({
                restaurantUnique: restaurant.restaurant_unique,
                country: restaurant.country_code,
            })
            .then((response) => {
                if (response.programs.length > 0) {
                    const list = response.programs.map((program) => {
                        program.programType =
                            program.config?.isDiscountEnabled &&
                            program.subscription.options.isActive &&
                            program.discounts.length > 0
                                ? LoyaltyProgramType.IN_SUB_DISCOUNT
                                : LoyaltyProgramType.IN_EARN_BURN;
                        return program;
                    });
                    setPrograms(list);
                    const theProgram = list.find((p) => p.id === selectedProgram?.id) ?? list[0];
                    setSelectedProgram(theProgram);
                    setLocalProgram(theProgram);
                } else {
                    setPrograms([]);
                    setSelectedProgram(null);
                    setLocalProgram(null);
                }
            })
            .catch(() => {
                setPrograms([]);
                setSelectedProgram(null);
                setLocalProgram(null);
            })
            .finally(() => setLoading(false));
    }

    function setSelectedProgramHandler(program: ILoyaltyProgram) {
        setSelectedProgram(program);
        setLocalProgram(program);
    }

    useEffect(() => {
        if (restaurant?.restaurant_unique) getLoyaltyPrograms();
    }, [restaurant?.restaurant_unique, user?.userData?.role]);

    return (
        <LoyaltyContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                loading,
                programs,
                setPrograms,
                selectedProgram,
                setSelectedProgram: setSelectedProgramHandler,
            }}
        >
            {children}
        </LoyaltyContext.Provider>
    );
}

export const useLoyaltyContext = () => useContext(LoyaltyContext);
